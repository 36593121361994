import React from "react"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import "../page-styles/contact.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="contact-container">
        <div style={{ textAlign: "center" }}>
          <h1>Thanks!</h1>
          <p>We will reach out to you shortly!</p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
